import React, { useEffect, useState } from 'react'
import Text from '../atoms/Text'
import IconTrending from 'atoms/SvgIcons/Icon.Trending.svg';
import Button from 'atoms/Button';
import { getTrendingMedicines } from 'services/medicine.service';
import { getUserSearch, updateUserSearchTermStatus } from 'services/user.service';
import { getAuth } from 'services/identity.service';
import SkeletonRecentSearchAndTrendingMeds from 'atoms/skeleton/SkeltonRecentSearchAndTrending';
import TrendingMedicineAndRecentSearchSummary from './TrendingMedicineAndRecentSearchSummary';
import * as moengage from "services/moengage.service";
import mo_engage_ecomm from "../assets/mo_engage/e_commerce";

import { useRouter } from 'next/router';
const buttonsData = [
    { label: 'Feed', path: '/feeds' },
    { label: 'Webinar', path: '/webinar' },
    { label: 'Quiz', path: '/quizzes' },
    { label: 'Learning', path: '/learning' },
];

const TrendingAndRecentSearch = ({ searchType, viewMedicineDetails, setShowRecentSearch, searchText, onChange, clearRef }) => {
    const [recentSearchedMedicines, setRecentSearchedMedicines] = useState([])
    const [trendingMedicines, setTrendingMedicines] = useState([])
    const [isFetchSearch, setIsFetchSearch] = useState(false)
    const router = useRouter()
    const entity = mo_engage_ecomm.entity;

    const getUserRecentSearchedMedicines = async () => {
        setIsFetchSearch(true);
        try {
            const userSearchTerm = await getUserSearch(getAuth()?.user?.id);
            const trendMedicinesRes = await getTrendingMedicines(0, 6, getAuth()?.user?.id ?? 0, 1).catch(console.error);
            setRecentSearchedMedicines(userSearchTerm?.entity);
            setTrendingMedicines(trendMedicinesRes?.status ? trendMedicinesRes?.entity?.list : []);
        } catch (err) {
            console.error("Error fetching recent searched medicines:", err);
        } finally {
            setIsFetchSearch(false);
        }
    };
    const removeSearchTerm = async () => {
        const itemIdsToUpdate = recentSearchedMedicines.map((obj) => obj.id);
        try {
            await updateUserSearchTermStatus(getAuth()?.user?.id, itemIdsToUpdate)
            setRecentSearchedMedicines([])
        }
        catch (err) {
            setRecentSearchedMedicines([])
        }
    }

    const onSearchEnter = (medicineName) => {
        router.push(`/medicines/allMedicines?search=${medicineName}`)
        moengage.trackEvent(entity.search_medicine.event_name, { [entity.search_medicine.attributes_search_medicine.search_text]: medicineName })
    }

    useEffect(() => {
        getUserRecentSearchedMedicines()
    }, [])

    return <>
        {isFetchSearch && <SkeletonRecentSearchAndTrendingMeds length={5} />}
        {!isFetchSearch && <>
                {searchType == 'medicine' && <div>
                    {recentSearchedMedicines?.length > 0 && <div className='pl-5 min-h-[46px] pt-3 pr-2 flex justify-between items-center bg-basic-white relative z-10 rounded-2xl'>
                        <Text className='capitalize text-gray-900' fontWeight={400}>{"Recent Searches"}</Text>
                        <button className='capitalize pr-3 text-primary1-500 smaller-font' fontWeight={600} color='text-primary1-500' onClick={(e) => { e.stopPropagation(); removeSearchTerm(); }} ref={clearRef} >{"clear"}</button>
                    </div>}

                    {recentSearchedMedicines?.map((item) => (
                        <TrendingMedicineAndRecentSearchSummary
                            className="p-5 pb-0 pt-0"
                            onChange={onChange}
                            onSearchEnter={onSearchEnter}
                            brand_name={null}
                            medicineName={item?.searchTerm}
                            searchTerm={item}
                            key={item.id}
                            setShowRecentSearch={setShowRecentSearch}
                        />
                    ))}

                    {trendingMedicines?.length > 0 && <div className='pl-5 min-h-[36px] pt-4 pr-2 flex justify-between items-center bg-basic-white relative z-10 rounded-2xl'>
                        <Text className='capitalize text-gray-900' fontWeight={400}>{"Trending Medicines"}</Text>
                    </div>}                  
                    {trendingMedicines?.map((item) => (
                        <TrendingMedicineAndRecentSearchSummary
                            onChange={onChange}
                            className="p-5 pb-0 pt-2"
                            type={"trending"}
                            viewMedicineDetails={viewMedicineDetails}
                            medicine={item}
                            medicineName={item?.medicineName}
                            key={item?.brandId}
                            setShowRecentSearch={setShowRecentSearch}
                        />
                    ))}

                    <div>
                        <div className='pl-5 min-h-[46px] pt-2 pr-2  md:mb-0 flex items-center bg-basic-white relative z-10 rounded-2xl'>
                            <IconTrending />
                            <Text type='body' className='ml-2' color='text-gray-900' fontWeight={400}>{"Discover More"} </Text>
                        </div>
                        <div className='pl-5 pr-2 mb-6 gap-1 flex items-center bg-basic-white relative z-10 rounded-2xl '>
                            {buttonsData.map(({ label, path }) => (
                                <Button key={label} onClick={() => router.push(path)} variant="Tertiary" size="small" className="border bg-basic-white text-gray-400"> {label} </Button>
                            ))}
                        </div>
                    </div>
                </div>}
        </>}
    </>
}
export default TrendingAndRecentSearch