import React from 'react'
import PropTypes from 'prop-types';
import Text from '../atoms/Text'
import ImageNext from 'atoms/ImageNext';
import IconLeftUpArrow from 'atoms/SvgIcons/iconUpArrow.svg';
import IconCircleWatch from 'atoms/SvgIcons/Icon.watch.svg';
const buckerUrl = process.env.NEXT_PUBLIC_BUCKET_URL

const TrendingMedicineAndRecentSearchSummary = ({ className, onChange, medicineName, type, viewMedicineDetails, medicine, onSearchEnter, setShowRecentSearch }) => {
    return (
        <div className={`${className} flex gap-1 w-full bg-basic-white transition-regular  min-h-[35px]`}>
            <div className='flex-grow'>
                <div className='flex items-center flex-wrap '>
                    <div className='flex items-center cursor-pointer'  onClick={(e) => {
                    type === "trending" ? viewMedicineDetails(medicine?.slug, medicine?.id, medicine?.medicineName, medicine?.brandName, medicine?.brandId, medicine?.mrp, medicine?.isDeal) : onSearchEnter(medicineName)
                }}> 
                        {type === 'trending' ? <div>
                            <ImageNext className='rounded-sm-0.5' width={27} height={27} src={medicine ? buckerUrl + medicine.image : '/images/nirogstreet_watermark.svg'} alterText={medicineName} defaultImageFor="nirogWaterMark" onErrorType='medicine' onErrorScaleLevel='1' />
                        </div> : <IconCircleWatch />
                        }
                        <Text style={{ maxWidth: "350px" }} type='body' className='ml-2 line-clamp-1' color='text-gray-500' fontWeight={400}>{medicineName}{medicine ? ` (${medicine?.brandName})` : null} </Text>
                    </div>
                    <div className='ml-auto cursor-pointer' onClick={() => { onChange && onChange(medicineName); setShowRecentSearch && setShowRecentSearch(false); }}>
                        <IconLeftUpArrow />
                    </div>
                </div>

            </div>
        </div >
    )
}

TrendingMedicineAndRecentSearchSummary.defaultProps = {
    className: '',
}
TrendingMedicineAndRecentSearchSummary.propTypes = {
    className: PropTypes.string,
    medicineName: PropTypes.string.isRequired,
    discountPrice: PropTypes.number.isRequired,
    price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    percentage: PropTypes.number.isRequired,
    image: PropTypes.string,
}
export default TrendingMedicineAndRecentSearchSummary