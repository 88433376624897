import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'

const ErrorImageTypes = {
    imageNotFound: '/images/image-not-found.svg',
    medicine: '/images/medicine-default.svg',
    medicine2x: '/images/medicine-default-2x.svg',
    nirogWaterMark: '/images/nirogstreet_watermark.svg',
    blog: '/images/icon_health_feed.svg',
    clinic: '/images/icon_clinics.svg',
    avatar: '/images/icon_avatar_default.svg',
    webinar: '/images/webinar-default.svg',
}

const ImageCard = React.forwardRef(({ className, src, alterText, alt, imageClassName, onError, imageWidth, imageHeight, layout, onErrorType = "medicine",
    onErrorScaleLevel, objectFit, onErrorObjectFit, objectPosition, priority = false, ...rest }, ref) => {
    const [imageSrc, setImageSrc] = React.useState(src);
    useEffect(() => { setImageSrc(src) }, [src])
    return (
        <div className={`${className} imageContainer relative`} {...rest}>
            <Image width={imageWidth} height={imageHeight} {...(!(imageWidth && imageHeight) && { layout })} ref={ref} src={imageSrc || src?.trim()} className={`${imageClassName} imageContainer__image`}
                alt={alterText || alt} objectPosition={objectPosition} onError={(e) => { console.log('Error res.hasHeader: ImageCard',imageSrc ); e.target.style.transform = `scale(${onErrorScaleLevel})`; setImageSrc(ErrorImageTypes[onErrorType]); e.target.style.objectFit = (onErrorType == 'medicine2x' || onErrorObjectFit) ? 'cover' : 'contain'; }}
                objectFit={objectFit} priority={priority}
            />
        </div>
    )
})

export default ImageCard

ImageCard.defaultProps = {
    className: '',
    imageClassName: '',
    onErrorScaleLevel: '0.6',
    layout: 'fill',
}

ImageCard.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    scaleLevel: PropTypes.string,
    layout: PropTypes.oneOf(['fill', 'fixed', 'intrinsic', 'responsive']),
};