import Avatar from 'atoms/Avatar';
import Card from 'atoms/Card'
import LinkAnchor from 'atoms/LinkAnchor';
import Text from 'atoms/Text';
import React from 'react'

const UserCard = (props) => {
    const { className, avatar, name, href } = props;
    return (
        <LinkAnchor href={href} className='w-full'>
            <Card type='square' className={`${className} flex items-center gap-4 shadow-none w-full bg-basic-white hover:bg-primary1-50 transition-regular cursor-pointer border-b border-gray-100 min-h-[50px]`}>
                {avatar && <Avatar className='w-7.5 h-7.5 flex-shrink-0' src={avatar} alt={name} />}
                <Text type='body' className='text-gray-900'>{name}</Text>
            </Card>
        </LinkAnchor>
    )
}

export default UserCard