import React from 'react'
import PropTypes from 'prop-types';

import Text from '../atoms/Text'
import Button from './../atoms/Button';
import { indianCurrency } from 'utils';
import ImageNext from 'atoms/ImageNext';
import { getAuth } from 'services/identity.service';
import IconDeleteV2 from 'atoms/SvgIcons/IconDeleteV2.svg';
import IconRupee from 'atoms/SvgIcons/IconRupee.svg';

const MedicineShortSummery = ({ className, id, packagePriceId, image, medicineName, percentage, price, discountPrice, size, type, handlerMedicineRemove, quantity, brandName, packageMarginId,
    totalCart, notifyMeQuantity, totalAvailableInventory, onClickSelect, noDiscount = false, index }) => {

    const auth = getAuth();
    const displayPrice = auth ? discountPrice : price;

    return (
        <div className={`${className} flex gap-3 w-full bg-basic-white hover:bg-primary1-50 transition-regular cursor-pointer border-t border-b border-gray-200 min-h-[75px]`}>

            {type !== 'removeType' && <div className='mt-1'>
                <ImageNext className='rounded-sm-0.5' width={30} height={30} src={image || '/images/nirogstreet_watermark.svg'} alterText={medicineName} defaultImageFor="nirogWaterMark" onErrorType='medicine' onErrorScaleLevel='1' />
            </div>}

            <div className='flex-grow'>

                {type === 'removeType'
                    ? <Text type='bodySmall' className='font-semibold text-gray-900 mb-0.5 line-clamp-1'>{medicineName} ({brandName}) <span className='text-gray-500'>{size}</span></Text>
                    : <div className='flex justify-between items-center'>
                        <Text type='bodySmall' className='font-bold text-gray-900 mb-0.5 line-clamp-1'>{medicineName} ({brandName})</Text>
                        {onClickSelect && <Button size={"extraSmall"} className="w-[85px]" onClick={onClickSelect} dataAutomation={`reseller-medicine-search-${index}`}>Select</Button>}
                    </div>
                }


                <div className='flex gap-2 items-center flex-wrap'>

                    {type === 'removeType'
                        ? <>
                            <div className='flex gap-1 items-center mt-0.5'>
                                <Text type='caption' fontWeight={400} className='text-gray-500'>Ordered:</Text>
                                <Text type='bodySmall' fontWeight={600} className='text-gray-900'>{totalCart}</Text>
                            </div>

                            <div className='flex gap-1 items-center mt-0.5'>
                                <Text type='caption' fontWeight={400} className='text-gray-500'> In-stock:</Text>
                                <Text type='bodySmall' fontWeight={600} className='text-semantic-success_base'>{totalAvailableInventory}</Text>
                            </div>

                            <div className='flex gap-1 items-center mt-0.5'>
                                <Text type='caption' fontWeight={400} className='text-gray-500'>Sold out:</Text>
                                <Text type='bodySmall' fontWeight={600} className='text-semantic-error_base'>{notifyMeQuantity}</Text>
                            </div>
                        </>
                        : <>
                            <Text type='bodySmall' fontWeight={600} className='text-gray-500'>Starts from </Text>

                            <div className='mb-1 md:mb-0 flex items-center'>
                                <IconRupee className="w-4 fill-primary1-500" />
                                <Text type='body' color='text-primary1-500' fontWeight={700}> {indianCurrency({ rupee: displayPrice, isShowRupeeSymbol: false })}</Text>
                            </div>

                            {auth && <div className='mb-1 md:mb-0 flex items-center'>
                                <IconRupee className="w-3.5 fill-gray-500" />
                                <Text className='' type='bodySmall' color='text-gray-500' fontWeight={600} ><del>{indianCurrency({ rupee: price, isShowRupeeSymbol: false })}</del></Text>
                            </div>}

                            {(auth && !noDiscount) && <Text type='captionSmall' className='text-primary2-500' fontWeight={700}>({percentage}% off)</Text>}
                        </>
                    }
                </div>
            </div>

            {type === 'removeType' && <div className='shrink-0 flex flex-col items-end'>
                <div className='mb-0.5 flex items-center'>
                    <IconRupee className="w-4 fill-primary1-500" />
                    <Text type='bodySmall' color='text-primary1-500' fontWeight={600} className=''>{indianCurrency({ rupee: parseFloat(discountPrice), isShowRupeeSymbol: false })}</Text>
                </div>
                <button onClick={() => handlerMedicineRemove(id, medicineName, brandName, size, discountPrice, quantity, packagePriceId,packageMarginId)} className='w-7 h-7 rounded-full bg-gray-50 shrink-0 flex justify-center items-center group hover:bg-gray-100 hover:shadow-sm' dataAutomation='remove-medicine'>
                    <IconDeleteV2 size={18} className='text-gray-400 group-hover:text-semantic-error_base' />
                </button>
            </div>}

        </div >
    )
}

MedicineShortSummery.defaultProps = {
    className: '',
}

MedicineShortSummery.propTypes = {
    className: PropTypes.string,
    medicineName: PropTypes.string.isRequired,
    discountPrice: PropTypes.number.isRequired,
    price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    percentage: PropTypes.number.isRequired,
    image: PropTypes.string,
}

export default MedicineShortSummery

