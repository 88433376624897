import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonRecentSearchAndTrendingMeds = ({ length }) => {
    return <> {Array.from({ length }).map((_, index) => (
        <div className={`flex gap-3 w-full bg-basic-white hover:cursor-pointer p-5 pb-2 min-h-13.25`}>
            <Skeleton width={30} height={30} className='flex-shrink-0' style={{ borderRadius: 5 }} />
            <Skeleton containerClassName='flex-grow' width={250} height={10} />
            <Skeleton width={30} height={20} />
        </div>
    ))}
        <div className='flex gap-5 w-full bg-basic-white hover:cursor-pointer p-5 pb-5 min-h-23.25  items-center'>
            <Skeleton width={70} height={40} style={{ borderRadius: 20 }} />
            <Skeleton width={70} height={40} style={{ borderRadius: 20 }} />
            <Skeleton width={70} height={40} style={{ borderRadius: 20 }} />
            <Skeleton width={70} height={40} style={{ borderRadius: 20 }} />
        </div></>
}
export default SkeletonRecentSearchAndTrendingMeds