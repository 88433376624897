import React, { useEffect, useRef, useState } from 'react';
import { MenuItem } from '@szhsin/react-menu'
import PropTypes from 'prop-types';
import EcommSearchMenu from './../organisms/EcommSearchMenu'
import MedicineShortSummery from './../molecules/MedicineShortSummery'
import SkeletonMedicineShortSummery from './skeleton/SkeletonMedicineShortSummery';
import MenuListing from './MenuListing';
import ClinicShortSummery from 'molecules/ClinicShortSummery';
import UserCard from 'molecules/UserCard';
import BlogShortSummery from 'molecules/BloghortSummery';
import SkeletonLoader from './skeleton/SkeletonLoader';
import IconChevronDown from './SvgIcons/IconChevronDown.svg';
import IconSearch from './SvgIcons/IconSearch.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconSortCategory from './SvgIcons/IconSortCategory.svg';
import TrendingAndRecentSearch from 'molecules/TrendingAndRecentSearch';
import IconClose from './SvgIcons/IconClose.svg';
import IconChevronDownThin from './SvgIcons/IconChevronDownThin.svg';

import { getAuth } from './../services/identity.service';
import { USER_TYPES } from 'constant';

const bucketName = process.env.NEXT_PUBLIC_BUCKET_NAME;
const PhpUrl = (process.env.NODE_ENV == 'development') ? "https://stg3web.1veda.in" : process.env.NEXT_PUBLIC_API_URL_PHP;
const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;

const classes = {
    small: 'smallSearchBox'
}

const SkeletonLoaderType = (searchType) => {
    switch (searchType) {
        case "medicine":
            return <SkeletonMedicineShortSummery length={3} />

        case "blog":
            return <SkeletonLoader type="userShortSummery" length={3} isCircularImage={false} minHeight={81} />

        case "clinic":
            return <SkeletonLoader type="userShortSummery" length={3} isCircularImage={false} minHeight={75} />

        case "doctor":
            return <SkeletonLoader type="userShortSummery" length={3} isCircularImage={true} minHeight={71} />

        case "location":
            return <SkeletonLoader type="singleRow" length={3} isCircularImage={true} minHeight={40} />

        default:
            return null;
    }
}

const Theme = {
    regular: 'searchBox',
    secondary: 'searchBox searchBox--secondary',
    universalSearch : 'searchBox searchBox--universal-search',
}

const InputSearch = React.forwardRef(({ className, inputClass, variant, placeholder, onChange, searchText, type, searchType, setSearchType, setSearchList = () => {},
    searchList, isShowSearch, setIsOpenSearch = () => {}, isFetchSearch, viewMedicineDetails, onSearchEnter = () => { }, onBlur = () => { }, isShowSearchDropdown, dataAutomation,
    isLocationSearch, onItemSelect = () => { }, theme = 'regular', getListOfGlobalSearch }, ref) => {
    const anchorRef = useRef(null);
    let clearRef = useRef(null)
    const [showRecentSearch, setShowRecentSearch] = useState(false)
    const auth = getAuth();
    useEffect(() => {
        const onClick = (event) => {
            if (!ref?.current?.contains(event.target) && !clearRef?.current?.contains(event.target)) {
                setIsOpenSearch && setIsOpenSearch(false);
                setShowRecentSearch(false);
            }
        };
        document.body.addEventListener("click", onClick);
        return () => {
            document.body.removeEventListener("click", onClick);
        };
    }, [clearRef, ref]);

    const handleSearchMedicine = (e) => {
        if (!e.target.value) { onChange(e.target.value); setShowRecentSearch(true); setSearchList([]) }
        else { setShowRecentSearch(false); onChange(e.target.value); }
    }

    const handleClickOnSearchBox = () => {
        if (searchText) {
            setIsOpenSearch(true)
            setShowRecentSearch(false)
        } else {
            setShowRecentSearch(true); setIsOpenSearch(true);
        }
    }

    return (
        <label className={`${className} ${isShowSearchDropdown ? dataAutomation === "post-category-search" ? 'pr-0' : 'pl-0' : ''} ${Theme[theme]} relative`} ref={anchorRef} htmlFor={'search'}>

            {isShowSearchDropdown && dataAutomation === 'universal-search' && <MenuListing type='bottom'
                onMenuChange={({ open }) => open && setIsOpenSearch(false)}
                menuButton={
                    <button className='text-gray-900 flex-shrink-0 text-14-18 font-semibold h-full flex w-31 bg-gray-100 rounded-l-3xl border-r border-gray-300 gap-1 group items-center pl-2 pr-4.5 justify-between mr-4.5'>
                        <div className='flex-shrink-0 grow capitalize group-hover:text-primary1-500'>{searchType === '' ? 'Looking for?' : searchType}</div>
                        <IconChevronDownThin className="w-2.5 text-gray-900 group-hover:text-primary1-500" />
                    </button>}>
                {<MenuItem onClick={() => { setSearchType('medicine'); setSearchList([]); onChange("") }} data-automation='search-medicine'>Medicine</MenuItem>}
                {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <MenuItem onClick={() => { setSearchType('blog'); setSearchList([]); onChange("") }} data-automation='search-blog'>Blog</MenuItem>}
                {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <MenuItem onClick={() => { setSearchType('clinic'); setSearchList([]); onChange("") }} data-automation='search-clinic'>Clinic</MenuItem>}
                {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <MenuItem onClick={() => { setSearchType('doctor'); setSearchList([]); onChange("") }} data-automation='search-doctors'>Doctors</MenuItem>}
            </MenuListing>}

            <IconSearch className='text-gray-600' size={20} />

            <input ref={ref} autoComplete="off" className={`${inputClass} searchInput ${classes[type] || ''}`} type="text" id="search" placeholder={placeholder} value={searchText} onChange={handleSearchMedicine} onKeyPress={onSearchEnter} data-automation={dataAutomation}
                onBlur={() => {
                    onBlur()
                }}
                onMouseUp={handleClickOnSearchBox}
            />
            {showRecentSearch && searchType == 'medicine' && <IconClose className='w-6 fill-none stroke-gray-800 cursor-pointer' />}

            {isShowSearchDropdown && dataAutomation === "post-category-search" && <MenuListing type='bottom'
                onMenuChange={({ open }) => open && setIsOpenSearch(false)}
                menuButton={
                    <button className='flex-shrink-0 text-sm font-semibold h-[34px] flex xs:w-48 bg-gray-200 rounded-3xl gap-1 group items-center px-5 justify-between'>
                        <IconSortCategory
                            className={`h-5 text-gray-600`}
                        />
                        <div className='flex-shrink-0 grow capitalize lg:text-sm text-xs'>{searchType}</div>
                        <IconChevronDown className="w-3 text-gray-900 group-hover:text-primary1-500" />
                    </button>
                }
                className="lg:mr-5 mr-2.5"
            >
                <MenuItem className="lg:text-sm text-xs" onClick={() => { setSearchType('Sort by A-Z') }} data-automation='sort-alphabetically'>Alphabetically (A-Z)</MenuItem>
                <MenuItem className="lg:text-sm text-xs" onClick={() => { setSearchType('Sort by Trending') }} data-automation='sort-trending-descending'>Trending</MenuItem>
            </MenuListing>}


            {isShowSearchDropdown && !showRecentSearch &&
                <EcommSearchMenu className='rounded-b-2xl' subClass='min-h-[200px] max-h-[374px]' isShowSearch={isShowSearch} setIsOpenSearch={setIsOpenSearch} title={`Search ${searchType + 's'}`} anchorRef={anchorRef}>

                    {isFetchSearch && SkeletonLoaderType(searchType)}

                    {!isFetchSearch && <>

                        {searchList.length === 0
                            && <div className='text-2xl text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>No result found</div>}

                        <InfiniteScroll dataLength={searchList?.length} next={() => getListOfGlobalSearch({ searchType, search: searchText })} hasMore={true} scrollableTarget="scrollableDiv">

                            {searchType == 'medicine' && searchList.map(({ id, slug, name, preview_img, brand_name, brand_id, price_data, is_deal, mrp, discountedMrp, discountPercentage }) => (
                                mrp &&
                                <MenuItem className='menu__searchList' key={id} onClick={() => viewMedicineDetails(slug, id, name, brand_name, brand_id, mrp, is_deal)}>
                                    <MedicineShortSummery
                                        className="p-5 pb-4"
                                        discountPrice={discountedMrp && discountedMrp}
                                        image={preview_img}
                                        medicineName={name}
                                        brandName={brand_name}
                                        percentage={discountPercentage && discountPercentage}
                                        price={mrp && mrp}
                                        size={price_data && price_data[0]?.medicine_packaging_size}
                                    />
                                </MenuItem>
                            ))}

                            {searchType == 'blog' && searchList.map(({ id, title, thumbnail, slug, href, BlogCategory: { name = '', } = {} }) => (
                                <MenuItem className='menu__searchList' key={id}>
                                    <BlogShortSummery
                                        className=""
                                        href={href}
                                        image={thumbnail}
                                        name={title}
                                        category={name}
                                    />
                                </MenuItem>
                            ))}

                            {searchType == 'clinic' && searchList.map(({ id, clinic_name, address, city, slug, preview_image }) => (
                                <MenuItem className='menu__searchList' key={id}>
                                    <ClinicShortSummery
                                        className=""
                                        href={`/clinics/${city.toLowerCase().split(' ').join('-')}/${slug}`}
                                        name={clinic_name}
                                        image={`${preview_image ? bucketUrl + preview_image : ''}`}
                                        description={address}
                                    />
                                </MenuItem>
                            ))}

                            {searchType == 'doctor' && searchList.map((item) => (
                                <MenuItem className='menu__searchList' key={item?.id}>
                                    <UserCard
                                        className=""
                                        href={item?.href}
                                        avatar={`${bucketUrl}${item?.profilePic ? item.profilePic : '/images/icon_doctor.svg'}`}
                                        name={item?.fullNameSalutation}
                                    />
                                </MenuItem>
                            ))}
                        </InfiniteScroll>
                    </>}

                </EcommSearchMenu>
            }

            {showRecentSearch && searchType == 'medicine' &&
                <EcommSearchMenu className='rounded-2xl' subClass='min-h-[120px] max-h-[610px]' isShowSearch={isShowSearch} setIsOpenSearch={setIsOpenSearch} anchorRef={anchorRef}>
                    <TrendingAndRecentSearch
                        searchType={searchType}
                        viewMedicineDetails={viewMedicineDetails}
                        onChange={onChange}
                        setIsOpenSearch={setIsOpenSearch}
                        setShowRecentSearch={setShowRecentSearch}
                        searchText={searchText}
                        clearRef={clearRef} />
                </EcommSearchMenu>
            }

            {isLocationSearch &&
                <EcommSearchMenu anchorRef={anchorRef} className='rounded-2xl' subClass='min-h-[60px] w-full max-h-[374px]' isShowSearch={isShowSearch} setIsOpenSearch={setIsOpenSearch} maxWidth={100} isDynamicWidth={true}>

                    {isFetchSearch && SkeletonLoaderType(searchType)}

                    {!isFetchSearch && <>

                        {searchList.length === 0
                            && <div className='text-sm text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>No result found</div>}


                        {searchType == 'location' && searchList.map(({ id, value }) => (
                            <MenuItem className='flex transition-regular  border-t border-b border-gray-100 px-5 items-center' key={id} onClick={() => onItemSelect({ id, value })}>
                                <div>hello</div>
                            </MenuItem>
                        ))}

                    </>}

                </EcommSearchMenu>
            }
        </label>
    )
});

InputSearch.defaultProps = {
    variant: 'default',
}

InputSearch.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    variant: PropTypes.oneOf(['ecomm', 'default']),
}

export default InputSearch;