import Card from 'atoms/Card'
import ImageCard from 'atoms/ImageCard';
import LinkAnchor from 'atoms/LinkAnchor';
import Text from 'atoms/Text';
import React from 'react'

const ClinicShortSummery = (props) => {
    const { className, image, name, description, href } = props;
    return (
        <LinkAnchor href={href} className='w-full'>
            <Card type='square' className={`${className} flex items-center gap-4 shadow-none w-full bg-basic-white hover:bg-primary1-50 transition-regular cursor-pointer border-b border-gray-100 min-h-[75px]`}>
                <ImageCard className='w-10 h-8 flex-shrink-0' src={image} alterText={name} onErrorType='clinic' onErrorScaleLevel='1' onErrorObjectFit="contain" />
                <div>
                    <Text type='body' fontWeight={600} className='text-gray-900 line-clamp-1'>{name}</Text>
                    <Text type='bodySmall' className='text-gray-500 line-clamp-1'>{description}</Text>
                </div>

            </Card>
        </LinkAnchor>
    )
}

export default ClinicShortSummery