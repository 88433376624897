import { Svg } from "atoms/SvgIcon";

const IconSortCategory = ({ ...rest }) => {
    return <Svg viewBox='0 0 15 14' type='IconSortCategory' {...rest} >
        <path d="M6.10802 10.8274H0.857422" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.4502 2.75032H13.7008" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.77158 2.70521C4.77158 1.6255 3.88978 0.75 2.80229 0.75C1.71481 0.75 0.833008 1.6255 0.833008 2.70521C0.833008 3.78492 1.71481 4.66042 2.80229 4.66042C3.88978 4.66042 4.77158 3.78492 4.77158 2.70521Z" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1661 10.7948C14.1661 9.71507 13.285 8.83957 12.1975 8.83957C11.1093 8.83957 10.2275 9.71507 10.2275 10.7948C10.2275 11.8745 11.1093 12.75 12.1975 12.75C13.285 12.75 14.1661 11.8745 14.1661 10.7948Z" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
};

export default IconSortCategory;